#clients {
    margin-top: 0px;
    margin-left: var(--side-width);
    margin-right: var(--side-width);
    padding-top: var(--half-side-width);
    padding-bottom: var(--half-side-width);
    padding-left: var(--half-side-width);
    padding-right: var(--half-side-width);
    color: var(--color-primary-grey-5);
    margin-bottom: 0px;
    border-radius: 30px 30px 0px 0px; }
    #clients-container-text {
        padding-top: 15px;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        display: flex; }
    #clients-container {
        padding-top: 20px;
        padding-bottom: var(--side-width); }
        #clients-container-left-arrow {}
        #clients-container-logos { 
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap; }
            #clients-container-logos .item {
                width: 200px;
                background-color: var(--color-neutral-0);
                padding: 20px;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                display: flex;
                border-radius: 10px 30px 20px 30px; 
                margin: 10px;
                filter: grayscale(); }
                #clients-container-logos .item:hover { filter: none; }
        #clients-container-right-arrow {}