#footer {
    background-color: var(--color-primary-grey-5);
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    align-items: center;
    height: 60px;
    color: var(--color-primary-turquoise-1);
    justify-content: space-between; }

@media screen and (min-width: 960px) {
    #footer { padding-left: 60px; padding-right: 60px; }}

@media screen and (min-width: 0px) and (max-width: 959px) { 
    #footer { padding-left: var(--half-side-width); padding-right: var(--half-side-width); } }

@media screen and (min-width: 0px) and (max-width: 1279px) {}