main {
    background-image: url('../../assets/images/backgrounds/degrade-grey.jpg');
    background-size: cover; }

@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; } }

@media screen and (min-width: 0px) and (max-width: 1279px) {
    .hide-if-small { display: none; } }