#portfolio-people {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: var(--side-width); }
    #portfolio-people-header {
        padding-top: var(--half-side-width);
        padding-left: var(--half-side-width); }
    #portfolio-people-cards {
        display: flex;
        flex-direction: column;
        color: var(--color-neutral-0); }
    #portfolio-people-footer {
        padding-top: 10px;
        padding-bottom: var(--half-side-width);
        padding-left: var(--half-side-width); }

@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #portfolio-people-footer { display: none; }}

@media screen and (min-width: 0px) and (max-width: 1279px) {}