/* -------------------------------
FONTS
------------------------------- */

/* Fonts > Import Font */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/gotham/GothamBook.ttf'); }

@font-face {
  font-family: 'Gotham-Book';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/gotham/GothamBook.ttf'); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/gotham/GothamMedium.ttf'); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/gotham/GothamBook.ttf'); }


@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/gotham/GothamBold.ttf'); }

@font-face {
  font-family: 'Gotham';
  /* IE9+ Compat Modes */
  src: url('../fonts/gotham/GothamBook.ttf') format('ttf');
  /* Legacy iOS */
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'KumbhSans';
  font-style: normal;
  src: url('../fonts/kumbh-sans/KumbhSans-Bold.ttf'); }

@font-face {
  font-family: 'KumbhSans';
  font-style: normal;
  src: url('../fonts/kumbh-sans/KumbhSans-Regular.ttf'); }

/* Fonts > Font Classes */
.Gotham_Book {
  font-family: 'Gotham', sans-serif;
  font-weight: 300; }

.Gotham_Medium {
  font-family: 'Gotham', sans-serif;
  font-weight: 600; }

.Gotham_Bold {
  font-family: 'Gotham', sans-serif;
  font-weight: 700; }

.KumbhSans-Bold900 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 900; }
    
.KumbhSans-Bold800 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 800; }
  
.KumbhSans-Bold700 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 700; }

.KumbhSans-Bold600 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 600; }

.KumbhSans-Regular500 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 500; }

.KumbhSans-Regular400 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 400; }
  
.KumbhSans-Regular300 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 300; }

.KumbhSans-Regular200 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 200; }

.KumbhSans-Regular100 {
  font-family: 'KumbhSans', sans-serif;
  font-weight: 100; }

/* -------------------------------
FONT SIZE
------------------------------- */

.font-size-display {
  font-size: var(--font-size-display);
  line-height: 1.25;
  }
  
  h1, .heading1 {
    font-size: var(--font-size-h1);
    line-height: 1.50;
  }
  
  h2, .heading2 {
    font-size: var(--font-size-h2);
    line-height: 1.4;
  }
  
  h3, .heading3 {
    font-size: var(--font-size-h3);
    line-height: 1.25;
  }
  
  h4, .heading4 {
    font-size: var(--font-size-h4);
    line-height: 1.2;
  }
  
  h5, .heading5 {
    font-size: var(--font-size-h5);
    line-height: 1.1;
  }
  
  h6, .heading6 {
    font-size: var(--font-size-h6);
    line-height: 1.05;
  }
  
  .font-size-big {
    font-size: 24px;
    line-height: 1.5;
  }

  .font-size-medium {
    font-size: 20px;
    line-height: 1.5;
  }
  
  .font-size-base {
    font-size: var(--font-size-base);
    line-height: 1.5;
  }
  
  .font-size-s {
    font-size: var(--font-size-s);
    line-height: 1.5;
  }
  
  .font-size-xs {
    font-size: var(--font-size-xs);
    line-height: 1.5;
  }

/* -------------------------------
FONT WEIGHT
------------------------------- */

.font-extra-light { font-weight: 300; }
.font-light { font-weight: 400; }
.font-book { font-weight: 600; }
.font-medium { font-weight: 700; }
.font-semi-bold { font-weight: 300; }
.font-bold { font-weight: 400; }
.font-extra-bold { font-weight: 600; }
.font-black { font-weight: 700; }

/* -------------------------------
TEXT TRANSFORM
------------------------------- */

.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }
.text-ellipsis { text-transform: ellipsis; }
.text-italic { text-transform: italic; }