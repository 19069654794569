.background-image-card-1{
    background-image: url('/src/assets/images/backgrounds/background-img1.png'); }

.background-image-card-2{
    background-image: url('/src/assets/images/backgrounds/background-img1.png'); }

.background-image-card-3{
    background-image: url('/src/assets/images/backgrounds/background-img1.png'); }

.background-image-card-4{
    background-image: url('/src/assets/images/backgrounds/background-img1.png'); }


.video-size {
    width: 560;
    height: 315;
}

.overview{
    max-height: 30vh; }

    .b-example-divider {
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }
      
      @media (min-width: 992px) {
        .rounded-lg-3 { border-radius: .3rem; }
      }