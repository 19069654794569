:root{
    /* Color values, variables and classes */
    --color-primary-grey-5: #1c1430;
    --color-primary-grey-4: #5a6473;
    --color-primary-grey-3: #777283;
    --color-primary-grey-2: #A4A1AC;
    --color-primary-grey-1: #D2D0D6;
    --color-primary-turquoise-5: #00D6B2;
    --color-primary-turquoise-4: #00D6B2;
    --color-primary-turquoise-3: #66E6D1;
    --color-primary-turquoise-2: #99EFE0;
    --color-primary-turquoise-1: #CCF7F0;
    --color-primary-blue-5: #14A7DD;
    --color-primary-blue-4: #6e8de4;
    --color-primary-blue-3: #92A9EB;
    --color-primary-blue-2: #B7C6F1;
    --color-primary-blue-1: #DBE2F8;
    --color-secondary-red: #E61728;
    --color-secondary-blue: #394D73;
    --color-secondary-grey: #F2F2F2;
    --color-secondary-yellow: #F0B145;
    --color-secondary-green: #63B29A;
    --color-neutral-5: #353c47;
    --color-neutral-4: #616771;
    --color-neutral-3: #a8aeb7;
    --color-neutral-2: #d1d3d6;
    --color-neutral-1: #eeeeee;
    --color-neutral-0: #ffffff;
    --color-info: #1e6ec8;
    --color-info-light: #e5f5fc;
    --color-success: #28b446;
    --color-success-light: #eaf2eb;
    --color-warning: #faaa14;
    --color-warning-light: #fdf6e5;
    --color-error: #f0321e;
    --color-error-light: #fbeaea;

    /* Font Size */
    --font-size-display: 36px;
    --font-size-h1: 32px;
    --font-size-h2: 28px;
    --font-size-h3: 26px;
    --font-size-h4: 22px;
    --font-size-h5: 20px;
    --font-size-h6: 18px;
    --font-size-base: 16px;
    --font-size-s: 14px;
    --font-size-xs: 12px;

    /* Typography - Weight */
    --font-extra-light: 200;
    --font-light: 300;
    --font-book: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: bold;
    --font-extra-bold: 800;
    --font-black: 900;

    /* Border Radius Classes and Variables */
    --border-radius-none: 0px;
    --border-radius-soft: 4px;
    --border-radius-rounded: 100px;
    --border-radius-circle: 100%;

    /* Border Sizes Classes and Variables */
    --border-size-none: 0px;
    --border-size-s: 1px;
    --border-size-m: 2px;
    --border-size-l: 3px;

    /* Uniform/ Directional Spacing */
    --space-none: 0px;
    --space-xs: 4px;
    --space-s: 8px;
    --space-base: 16px;
    --space-m: 24px;
    --space-l: 32px;
    --space-xl: 40px;
    --space-xxl: 48px;
    
    /* Shadows Classes and Variables */
    --shadow-none: none;
    --shadow-xs: 0 1px 2px rgba(0, 0, 0, .1);
    --shadow-s: 0 2px 4px rgba(0, 0, 0, .1);
    --shadow-m: 0 4px 6px rgba(0, 0, 0, .1);
    --shadow-l: 0 6px 8px rgba(0, 0, 0, .1);
    --shadow-xl: 0 8px 10px rgba(0, 0, 0, .1);

    /* App Settings */
    --color-background-body: #eeeeee;
    --header-size: 90px;
    --header-slide-size: 500px;
    --side-menu-size: 300px;
    --footer-height-size: 180px;
    --social-icons-menu-sizing: 50px;
    --side-width: 40px;
    --half-side-width: 20px;
    
    /* Cards */
    --card-width-xxl: 600px;
    --card-height-xxl: 400px;
    --card-width-xl: 350px;
    --card-height-xl: 250px;
    --card-width-l: 250px;
    --card-height-l: 350px;
    --card-width-m: 200px;
    --card-height-m: 300px;
    --card-width-s: 150px;
    --card-height-s: 200px;
    --card-width-xs: 100px;
    --card-height-xs: 100px;
}