#top-menu {
    z-index: 10000;
    padding-left: var(--half-side-width);
    padding-right: var(--half-side-width);
    position: fixed;
    display: flex;
    padding-top: 10px;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: var(--color-primary-grey-5); }
    #hamburguer-menu {
        display: none; }
    #menu {
        display: flex;
        align-items: baseline;
        justify-content: space-between; }
        #logo {}
            #logo .image {
                height: 80px; }
        #top-menu-items {}
            #top-menu-items a {
                text-decoration: none;
                color: white; }
            #top-menu-items .items {
                display: flex;
                list-style-type: none; }
                #top-menu .first-item {
                    padding-left: 0px; }
                #top-menu .item {
                    padding-left: 20px;}
        #hire-us-button {
            margin-left: var(--side-width);
            background-color: var(--color-neutral-0);
            color: var(--color-primary-grey-5); }
    #language-selector {
        appearance: none;
        position: relative;
        display: block; }

@media screen and (min-width: 960px) {
    #top-menu { padding-left: 60px; padding-right: 60px; }
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #top-menu { padding-left: var(--half-side-width); padding-right: var(--half-side-width); }
    #top-menu-items { display: none; } }

@media screen and (min-width: 0px) and (max-width: 1279px) {
    #hire-us-button  { display: none; } }