#services {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: var(--half-side-width);
    color: var(--color-primary-grey-5);
    background-size: cover; }
    #services-portfolios-title {
        padding-top: 15px;
        padding-bottom: 0px;
        color: var(--color-primary-grey-2);
        padding-left: 20px; }
    #services-portfolios-subtitle {
        padding-top: 0px;
        margin-bottom: 0px;
        color: var(--color-primary-grey-2);
        padding-left: 20px; }
    #services-portfolios {
        padding-left: var(--side-width);
        padding-right: var(--side-width);
        display: flex;
        flex-direction: row;
        width: 100%; }

@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #services-portfolios {
        padding-right: 0px;
        display: flex;
        flex-direction: column; }}

@media screen and (min-width: 0px) and (max-width: 1279px) {}