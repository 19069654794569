#about {
    display: flex;
    color: var(--color-primary-grey-1);
    background-color: var(--color-primary-grey-5);
    background-size: cover; }
    #about-left {}
        #about-left-rectangule {
            border-radius: 0px 0px 20px 0px;
            background-color: var(--color-secondary-green);
            width: 50px;
            margin-right: 30px;
            height: 70%; }
    #about-content {
        display: flex;
        align-items: baseline;
        padding-top: var(--side-width);
        padding-bottom: var(--side-width); }
        #about-content-left {
            flex: 1;
            margin-right: var(--half-side-width); }
        #about-content-right {
            flex: 1;
            margin-left: 10px; }
            #about-content-right-top-text {
                padding-bottom: var(--half-side-width); }
            #about-content-right-sectors {
                padding-top: var(--half-side-width);
                display: flex; }
                #about-content-right-sector-techs {
                    color: var(--color-secondary-green);
                    font-size: x-large;
                    flex: 2; }
                #about-content-right-sector-industry {
                    color: var(--color-secondary-blue);
                    font-size: x-large;
                    flex: 2; }
                #about-content-right-sector-gov {
                    color: var(--color-secondary-yellow);
                    font-size: x-large;
                    flex: 2; }
    #about-right {}
    #about-right-rectangule {
        border-radius: 0px 0px 0px 20px;
        background-color: var(--color-secondary-yellow);
        width: 80px;
        height: 30%; }

@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #about-left { display: none; }
    #about-content { padding-left: 20px; padding-right: 20px; }
    #about-right { display: none; } }

@media screen and (min-width: 0px) and (max-width: 1279px) {}