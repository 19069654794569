button, select { 
    border-radius: 10px 20px 30px 20px; 
    padding-left: 20px; 
    padding-right: 20px; 
    padding-top: 10px;  
    padding-bottom: 10px; }

.button-grey {
    border-radius: 10px 20px 30px 20px; 
    padding-left: 20px; 
    padding-right: 20px; 
    padding-top: 10px;  
    padding-bottom: 10px;
    background-color: var(--color-primary-grey-5);
    color: var(--color-neutral-0); }
    .button-grey:hover {
        color: var(--color-secondary-green); }

.btn-primary {
    color: var(--color-primary-grey-5);
    border-radius: 5px;
    background-color: var(--color-neutral-0);
    border-color: var(--color-secondary-blue);
    border-radius: 5px; }
    .button-primary:hover {
        color: var(--color-secondary-green); }

.btn-outline-primary {
    color: #FFF;
    border-radius: 5px;
    border-color: var(--color-secondary-green); }
        
select {
    background-color: var(--color-primary-grey-5);
    border-color: var(--color-primary-grey-4);
    color: var(--color-neutral-0); }

.bg-color-grey-5 { background-color: var(--color-primary-grey-5); }
.bg-color-grey-4 { background-color: var(--color-primary-grey-4); }
.bg-color-grey-3 { background-color: var(--color-primary-grey-3); }
.bg-color-grey-2 { background-color: var(--color-primary-grey-2); }
.bg-color-grey-1 { background-color: var(--color-primary-grey-1); }
.bg-color-blue-5 { background-color: var(--color-primary-blue-5); }
.bg-color-blue-4 { background-color: var(--color-primary-blue-4); }
.bg-color-blue-3 { background-color: var(--color-primary-blue-3); }
.bg-color-blue-2 { background-color: var(--color-primary-blue-2); }
.bg-color-blue-1 { background-color: var(--color-primary-blue-1); }
.bg-color-turquoise-5 { background-color: var(--color-primary-turquoise-5); }
.bg-color-turquoise-4 { background-color: var(--color-primary-turquoise-4); }
.bg-color-turquoise-3 { background-color: var(--color-primary-turquoise-3); }
.bg-color-turquoise-2 { background-color: var(--color-primary-turquoise-2); }
.bg-color-turquoise-1 { background-color: var(--color-primary-turquoise-1); }
.bg-color-green { background-color: var(--color-secondary-green); }
.bg-color-blue { background-color: var(--color-secondary-blue); }
.bg-color-red { background-color: var(--color-secondary-red); }
.bg-color-yellow { background-color: var(--color-secondary-yellow); }