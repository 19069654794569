.social {
    z-index: 100020;
    position: fixed;
    bottom: 10% !important;
    left: auto;
    right: 40px;
    float: left;
    width: 3pc;
    box-sizing: border-box; }

    .social .social-item {
        padding: 10px;
        margin-bottom: 20px;
        background-color: var(--color-primary-blue-1);
        border-radius: 4px; }

    .social .social-item:hover { background-color: aliceblue; }

.linkedin {
    content: url('../../../assets/images/social/linkedin-cinzento.png');
    height: var(--social-icons-menu-sizing);
}

.linkedin:hover {
    content: url('../../../assets/images/social/linkedin-cinzento.png');
    height: var(--social-icons-menu-sizing);
}

.twitter {
    content: url('../../../assets/images/social/twitter.png');
    height: var(--social-icons-menu-sizing);
}

.twitter:hover {
    content: url('../../../assets/images/social/twitter-cinzento.png');
    height: var(--social-icons-menu-sizing);
}

.instagram {
    content: url('../../../assets/images/social/instagram.png');
    height: var(--social-icons-menu-sizing);
}

.instagram:hover {
    content: url('../../../assets/images/social/instagram-cinzento.png');
    height: var(--social-icons-menu-sizing);
}

.facebook {
    content: url('../../../assets/images/social/facebook.png');
    height: var(--social-icons-menu-sizing);
}

.facebook:hover {
    content: url('../../../assets/images/social/facebook-cinzento.png');
    height: var(--social-icons-menu-sizing);
}