#slideshow {
    display: flex;
    align-items: flex-end;
    padding-top: 100px;
    height: 600px;
    background-color: var(--color-primary-grey-5);
    background-image: url('../../../assets/images/backgrounds/fundo6.jpg');
    background-size: cover; }
    #slide1 {
        border-radius: 0px 50px 0px 0px;
        background-image: url('../../../assets/images/backgrounds/background-green-sec2.png');
        opacity: 0.5;
        background-size: cover;
        height:30%;
        width: 25%; }
    #slide2 {
        border-radius: 0px 50px 0px 0px;
        padding-bottom: var(--half-side-width);
        padding-left: 45px;
        background-image: url('../../../assets/images/backgrounds/background-grey.png');
        opacity: 1;
        background-size: cover;
        color: var(--color-neutral-0);
        height:30%;
        width: 50%; }
        #slide4 img {
            height: 30px;
            margin-left: 10px;
            border-radius: 5px 5px 5px 5px;
        }
    #slide3 {
        padding: var(--side-width);
        background-image: url('../../../assets/images/backgrounds/background-slide.png');
        background-size: cover;
        color: var(--color-neutral-0);
        width: 50%; }
        #slide3 img {
            height: 30px;
            margin-left: 10px;
            border-radius: 5px 5px 5px 5px;
        }
    #slide4 {
        border-radius: 50px 0px 0px 0px;
        background-image: url('../../../assets/images/backgrounds/background-yellow.png');
        opacity: 0.5;
        background-size: cover;
        height:10%;
        width: 25%; }

@media screen and (min-width: 960px) {
    .hide-if-big { display: none; }
    #welcome-img { display: none; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #welcome-img { display: block; }
    #slide2-text { display: none; }
    #slide3 { width: 100%; padding-right: var(--half-side-width); padding-left: var(--half-side-width); }}
