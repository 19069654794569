#portfolio-digital {
    flex: 2;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column; }
    #portfolio-digital-header {
        padding-top: var(--half-side-width);
        padding-left: var(--half-side-width); }
    #portfolio-digital-cards {
        display: flex;
        flex-wrap: wrap;
        color: var(--color-neutral-0); }
        #card-enterprise-design {
            border-style: solid;
            border-radius: 30px 0px 0px 0px; 
            padding-top: var(--half-side-width);
            padding-left: var(--half-side-width);
            padding-right: var(--half-side-width);
            height: 360px;
            background-image: url('../../../../assets/images/service-cards/digital-product.png');
            background-size: cover; 
            box-sizing: border-box;
            width: 50%;
            filter: grayscale(); }
            #card-enterprise-design:hover { filter: none; }
        #card-service-design {
            border-style: solid;
            border-radius: 0px 30px 0px 0px; 
            padding-top: var(--half-side-width);
            padding-left: var(--half-side-width);
            padding-right: var(--half-side-width);
            height: 360px;
            background-image: url('../../../../assets/images/service-cards/digital-service.png');
            background-size: cover; 
            box-sizing: border-box;
            width: 50%;
            filter: grayscale(); }
            #card-service-design:hover { filter: none; }
        #card-product-design {
            border-style: solid;
            border-radius: 0px 0px 0px 30px; 
            padding-top: var(--half-side-width);
            padding-left: var(--half-side-width);
            padding-right: var(--half-side-width);
            height: 360px;
            background-image: url('../../../../assets/images/service-cards/digital-data.png');
            background-size: cover; 
            box-sizing: border-box;
            width: 50%;
            filter: grayscale(); }
            #card-product-design:hover { filter: none; }
        #card-data-design {
            border-style: solid;
            border-radius: 0px 0px 30px 0px;
            padding-top: var(--half-side-width);
            padding-left: var(--half-side-width);
            padding-right: var(--half-side-width);
            height: 360px;
            background-image: url('../../../../assets/images/service-cards/digital-enterprise.png');
            background-size: cover; 
            box-sizing: border-box;
            width: 50%;
            filter: grayscale(); }
            #card-data-design:hover { filter: none; }
    #portfolio-digital-footer {
        padding-top: 10px;
        padding-bottom: var(--half-side-width);
        padding-left: var(--half-side-width); }

@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #card-enterprise-design { 
        margin-right: var(--side-width);
        width: 100%;
        border-radius: 30px 0px 0px 0px; }
    #card-service-design { 
        margin-right: var(--side-width);
        width: 100%;
        border-radius: 0px 0px 30px 0px; }
    #card-product-design {
        margin-top: var(--half-side-width);; 
        margin-right: var(--side-width);
        width: 100%;
        border-radius: 30px 0px 0px 0px; }
    #card-data-design { 
        margin-right: var(--side-width);
        width: 100%;
        border-radius: 0px 0px 30px 0px; }
    #portfolio-digital-footer { display: none; }}

@media screen and (min-width: 0px) and (max-width: 1279px) {}