#differentials {
    margin-top: 0px;
    margin-left: var(--side-width);
    margin-right: var(--side-width);
    margin-bottom: 30px;
    padding-top: var(--half-side-width);
    padding-left: var(--half-side-width);
    padding-right: var(--half-side-width);
    border-radius: 20px 20px 20px 20px;
    background-image: url('../../../assets/images/backgrounds/degrade-blue.jpg'); }
    #differentials-items {
        flex-direction: row;
        display: flex; }
        #differentials-items .item {
            display: flex;
            padding-bottom: var(--half-side-width);
            flex-direction: column;
            align-items: center; 
            flex: 1; }
            #differentials-items .item img {
                height: 100px;
                width: 100px;
                margin-bottom: 20px; }
    
@media screen and (min-width: 960px) {
    .hide-if-small { display: block; } }

@media screen and (min-width: 0px) and (max-width: 959px) {
    .hide-if-small { display: none; }
    #differentials { display: none; }}

@media screen and (min-width: 0px) and (max-width: 1279px) {}